import Vue from 'vue';
import get from 'lodash/get';
import { Client } from '@/services';
import { Services, M_SET_API_ALERT } from '../../../store/actions/api';
import STATES from '../../../store/actions/states';
import {
  PLAYER_REQUEST,
  PLAYER_SUCCESS,
  PLAYER_REPORT_REQUEST,
  PLAYER_REPORT_REQUEST_SUCCESS,
  PLAYER_REPORT_REQUEST_ERROR,
  PLAYER_STATS_REQUEST,
  PLAYER_STATS_REQUEST_SUCCESS,
  PLAYER_STATS_REQUEST_ERROR,
} from '../../../store/actions/player';

const GETTERS = {
  getCurrentProfileStatus(state) {
    return (id) => get(state.profiles, `${id}.status`, STATES.EMPTY);
  },
  getCurrentProfile(state) {
    return (id) => get(state.profiles, `${id}.data`, undefined);
  },
  getProfileById: (state) => (id) => get(state.profiles, id, { status: '', data: undefined }),

  getReportByKey: (state) => (key) => get(state.reports, key, undefined),

  geStatsByKey: (state) => (key) => get(state.stats, key, undefined),

  reportAvailable: (state) => state.reportStatus === STATES.SUCCESS,

  reportLoading: (state) => state.reportStatus === STATES.LOADING,

  statsAvailable: (state) => state.statsStatus === STATES.SUCCESS,

  statsLoading: (state) => state.statsStatus === STATES.LOADING,
};

const getDefaultFilters = () => {
  return {
    offset: 0,
    status: '',
  };
};

const ACTIONS = {
  initialize({ dispatch }) {
    console.info('Player Profiles initializing...');
    console.info('Player Profiles initialized.');
  },

  [PLAYER_REQUEST]: ({
    state, commit, dispatch, getters
  }, id) => new Promise((resolve, reject) => {
    if ([STATES.LOADING, STATES.UNAUTHORIZED].includes(state.status)) {
      reject(state.status);
      return;
    }
    if (!id || typeof id !== 'number') {
      reject(new Error('Invalid params'));
      return;
    }
    commit(PLAYER_REQUEST, id);
    let params = {
      method: Services.BaseballPlayer.methods.GetTagDetails.key,
      service: Services.BaseballPlayer.key,
      TagID: id,
    };

    // eslint-disable-next-line no-unreachable
    const axiosPromise = Client({ params });

    axiosPromise
      .then(
        (resp) => {
          commit(PLAYER_SUCCESS, { id, data: resp.data });
          resolve();
        },
        (resp) => reject(resp),
      )
      .catch((err) => {
        commit(M_SET_API_ALERT, {
          type: 'error',
          message: err.message,
        });
        reject(err.message);
      });
  }),

  [PLAYER_REPORT_REQUEST]: ({
    state, commit, dispatch, getters
  }, {
    Key, Id, Context, PitchDataFilter, HitDataFilter, Filters
  }) => new Promise((resolve, reject) => {
    if ([STATES.LOADING, STATES.UNAUTHORIZED].includes(state.reportStatus)) {
      reject(state.reportStatus);
      return;
    }

    if (!Id || typeof Id !== 'number') {
      reject(new Error('Invalid params'));
      return;
    }

    commit(PLAYER_REPORT_REQUEST, { Key });

    let params = {
      method: Services.BaseballReport.methods.GetTagReport.key,
      service: Services.BaseballReport.key,
      TagID: Id,
    };

    if (Context) {
      params['Context'] = Context;
    }

    if (PitchDataFilter) {
      params['PitchDataFilter'] = PitchDataFilter;
    }

    if (HitDataFilter) {
      params['HitDataFilter'] = HitDataFilter;
    }


    if (Filters) {
      let { seasonId, selectedDatesRange, gameType, team } = Filters;

      if (seasonId) {
        params['SeasonID'] = seasonId;
      }

      if (selectedDatesRange && selectedDatesRange.length) {

        if (selectedDatesRange.length === 2) {
          selectedDatesRange.sort();
        }

        params['DateStart'] = selectedDatesRange[0];

        if (selectedDatesRange.length === 2) {
          params['DateEnd'] = selectedDatesRange[1];
        } else {
          params['DateEnd'] = selectedDatesRange[0];
        }
      }

      if (gameType && Array.isArray(gameType)) {
        const [sessionType, dataVerified] = gameType;
        params['SessionType'] = sessionType;
        params['DataVerified'] = dataVerified;
      }

      if (team) {
        params['TeamID'] = team;
      }
    }

    // eslint-disable-next-line no-unreachable
    const axiosPromise = Client({ params });

    axiosPromise
      .then(
        (resp) => {
          Vue.$log.debug(resp);
          commit(PLAYER_REPORT_REQUEST_SUCCESS, { Key, PitchDataFilter, HitDataFilter, data: resp.data });
          resolve(resp.data);
        },
        (resp) => {
          Vue.$log.debug(resp);
          commit(PLAYER_REPORT_REQUEST_ERROR, { Key });
          reject(resp);
        },
      )
      .catch((err) => {
        Vue.$log.debug(err);
        commit(PLAYER_REPORT_REQUEST_ERROR, { Key });
        commit(M_SET_API_ALERT, {
          type: 'error',
          message: err.message,
        });
        reject(err.message);
      });
  }),

  // TODO: consider removing this method
  [PLAYER_STATS_REQUEST]: ({
    state, commit, dispatch, getters
  }, {
    Key, Id, Context, PitchDataFilter, HitDataFilter, Filters
  }) => new Promise((resolve, reject) => {
    // todo = refactor
    if ([STATES.LOADING, STATES.UNAUTHORIZED].includes(state.statsStatus)) {
      reject(state.statsStatus);
      return;
    }

    if (!Id || typeof Id !== 'number') {
      reject(new Error('Invalid params'));
      return;
    }

    commit(PLAYER_STATS_REQUEST, { Key });

    let params = {
      method: Services.BaseballReport.methods.GetTagReport.key,
      service: Services.BaseballReport.key,
      TagID: Id,
    };

    if (Context) {
      params['Context'] = Context;
    }

    if (PitchDataFilter) {
      params['PitchDataFilter'] = PitchDataFilter;
    }

    if (HitDataFilter) {
      params['HitDataFilter'] = HitDataFilter;
    }


    if (Filters) {
      let {
        seasonId, selectedDatesRange, gameType, team
      } = Filters;

      if (seasonId) {
        params['SeasonID'] = seasonId;
      }

      if (selectedDatesRange && selectedDatesRange.length) {
        if (selectedDatesRange.length === 2) {
          selectedDatesRange.sort();
        }

        params['DateStart'] = selectedDatesRange[0];

        if (selectedDatesRange.length === 2) {
          params['DateEnd'] = selectedDatesRange[1];
        } else {
          params['DateEnd'] = selectedDatesRange[0];
        }
      }

      if (gameType) {
        params['SessionType'] = gameType;
      }

      if (team) {
        params['TeamID'] = team;
      }
    }

    // eslint-disable-next-line no-unreachable
    const axiosPromise = Client({ params });

    axiosPromise
      .then(
        (resp) => {
          Vue.$log.debug(resp);
          commit(PLAYER_STATS_REQUEST_SUCCESS, {
            Key, PitchDataFilter, HitDataFilter, data: resp.data
          });
          resolve(resp.data);
        },
        (resp) => {
          Vue.$log.debug(resp);
          commit(PLAYER_STATS_REQUEST_ERROR, { Key });
          reject(resp);
        },
      )
      .catch((err) => {
        Vue.$log.debug(err);
        commit(PLAYER_STATS_REQUEST_ERROR, { Key });
        commit(M_SET_API_ALERT, {
          type: 'error',
          message: err.message,
        });
        reject(err.message);
      });
  }),
};

const MUTATIONS = {
  [PLAYER_REQUEST]: (state, playerId) => {
    Vue.set(state.profiles, playerId, {
      data: null,
      status: STATES.LOADING,
    });
  },
  [PLAYER_SUCCESS]: (state, { id, data }) => {
    if (!data) {
      return;
    }
    Vue.set(state.profiles, id, {
      data,
      status: STATES.SUCCESS,
    });
  },

  [PLAYER_REPORT_REQUEST]: (state, { Key }) => {
    Vue.set(state.reports, Key, {
      data: null,
      params: undefined,
    });
    state.reportStatus = STATES.LOADING;
  },

  [PLAYER_REPORT_REQUEST_ERROR]: (state, { Key }) => {
    Vue.set(state.reports, Key, {
      data: null,
      params: undefined,
    });
    state.reportStatus = STATES.ERROR;
  },

  [PLAYER_REPORT_REQUEST_SUCCESS]: (state, { Key, PitchDataFilter, HitDataFilter, data }) => {
    if (!data) {
      return;
    }
    Vue.set(state.reports, Key, {
      data,
    });
    state.reportStatus = STATES.LOADING;
    Vue.set(state.reports, Key, {
      data,
      params: { PitchDataFilter, HitDataFilter }
    });
    state.reportStatus = STATES.SUCCESS;
  },

  [PLAYER_STATS_REQUEST]: (state, { Key }) => {
    Vue.set(state.stats, Key, {
      data: null,
      params: undefined,
    });
    state.statsStatus = STATES.LOADING;
  },

  [PLAYER_STATS_REQUEST_ERROR]: (state, { Key }) => {
    Vue.set(state.stats, Key, {
      data: null,
      params: undefined,
    });
    state.statsStatus = STATES.ERROR;
  },

  [PLAYER_STATS_REQUEST_SUCCESS]: (state, { Key, PitchDataFilter, HitDataFilter, data }) => {
    if (!data) {
      return;
    }
    Vue.set(state.stats, Key, {
      data,
    });
    state.statsStatus = STATES.LOADING;
    Vue.set(state.stats, Key, {
      data,
      params: { PitchDataFilter, HitDataFilter }
    });
    state.statsStatus = STATES.SUCCESS;
  },
};

export default {
  namespaced: true,
  state() {
    return {
      profiles: {},
      reports: {},
      stats: {},
      reportStatus: STATES.EMPTY,
      statsStatus: STATES.EMPTY,
      status: STATES.EMPTY,
      context: '',
      reportType: '',
      filter: getDefaultFilters(),
      selectedReportTypeId: 0,
    };
  },
  getters: GETTERS,
  actions: ACTIONS,
  mutations: MUTATIONS,
};
