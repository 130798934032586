import { EReportContext } from '@/enums/reports';

export default [
  {
    path: '/player/:id',
    name: 'player',
    meta: { requiresAuth: true },
    components: {
      header: () => import('@/components/core/CoreHeader.vue'),
      drawer: () => import('@/components/core/CoreDrawer.vue'),
      default: () => import(/* webpackChunkName: "playerProfiles" */ '@/modules/player-profiles/PlayerProfile.vue'),
    },
    redirect: {
      name: 'player.report',
      path: {
        context: EReportContext.BATTING.key,
        reportType: 'summary',
      },
    },
    props: {
      default: (route) => {
        const { id, context, reportType } = route.params;
        return {
          id: Number(id),
          context: context || undefined,
          reportType: reportType || undefined,
        };
      },
    },
    children: [
      {
        path: ':context(batting|pitching)/:reportType',
        name: 'player.report',
        component: () => import(/* webpackChunkName: "playerProfiles" */ '@/modules/player-profiles/ProfileReport.vue'),
        props: (route) => {
          const { id, context, reportType } = route.params;
          return {
            id: Number(id) || undefined,
            context: context || undefined,
            reportType: reportType || undefined,
          };
        },
        // TODO: consider some route guard depending on CASL
      },
      {
        path: ':reportType(videos)/:context(batting|pitching)/:action?/:vid(\\d+)?/:share?',
        name: 'player.videoList',
        component: () => import(
          /* webpackChunkName: "playerProfiles" */
          '@/modules/player-profiles/ProfileReport.vue'
        ),
        props: (route) => {
          const { id, context, reportType } = route.params;
          return {
            id: Number(id) || undefined,
            context: context || undefined,
            reportType: reportType || undefined,
          };
        },
      },
    ],
  }
];
